import { useContext } from "react";
import Image from "../img/apple.png"
import { ProductContext } from "../context/productContext";
import { getHostURL } from "./url";


const ProductElement = ({product}) =>{
    const {wishlist, setWishlist, addItemToCart} = useContext(ProductContext);
    const hostUrl = getHostURL()

    const openDetailPage = (product) =>{
        let url = hostUrl + "product/details/" + product.url
        window.location.href = url
        console.log(url);
    }
    return(
        <>
           <div className="product-item">
                <div className="product">
                    <div className="ps-0" onClick={() => openDetailPage(product)}>
                        <h5>{product.productname} </h5>
                        <img src={Image} alt="" className="product-img" />
                        <p className="m-1">{product.details}</p>
                        <h6 className="m-2">Rs. {product.price}</h6>
                    </div>
                    <div className="wish-icon">
                        <button className="btn btn-outline-danger m-1" onClick={(e) => {e.stopPropagation(); setWishlist(product.productid);}}>
                            <i className={(wishlist.find((wishId) => wishId === product.productid)) ? "fa fa-heart" : "fa-regular fa-heart"}></i>
                        </button>
                    </div>
                    <p>
                        <button className="btn btn-success m-1" onClick={addItemToCart.bind(this, product)}>
                            <i className="fa fa-cart-arrow-down"></i> Add to Cart
                        </button>
                    </p>
                </div>
            </div>
        </>
    )
}

export default ProductElement;
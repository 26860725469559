import {  useContext, useEffect, useState } from "react";
import { ProductContext } from "../context/productContext";
import {  getBaseURL } from "./url";

const BrandSearch = () =>{
    const[brandlist, updateBrandlist] = useState([]);
    const { selectedBrand, updateSelectedBrand} = useContext(ProductContext)
    const baseURL = getBaseURL();

    const getBrands = async() =>{
        let url =  baseURL + "brand/getall";
        await fetch(url)
        .then(response=>response.json())
        .then(resultArr=>{
            let newData = resultArr;
            updateBrandlist(newData);
        })
    }

    useEffect(()=>{getBrands();}, [])
    return( 
        <div id="scrollable" style={{ height: "200px", overflowY: "scroll" }} className="pt-1" >
            {brandlist.map((brand, index)=>{
                return(
                        <p className={`scroll-element ps-2 ${selectedBrand.brandid === brand.brandid ? 'highlighted' : ''}`} 
                        key={index} onClick={updateSelectedBrand.bind(this, brand)}>{brand.brandname}</p>            
                )
            })}
        </div>
    )
}


export default BrandSearch;
import { useEffect, useState } from "react"
import { getHostURL } from "./url";


export const SearchSuggest = ({productFound, setSearch}) =>{
    const[suggestionArr, updateSuggestionArr] = useState([])
    const [showSuggestions, setShowSuggestions] = useState(true);
    const HostUrl = getHostURL()
   

    const handleSuggestionClick = (value) => {
        console.log(value)
        setShowSuggestions(false)
        if(value.url != ""){
            if(value.type == "C" || value.type == "B"){
                window.location.href =  HostUrl + "search/" + value.url;
                
            }else{
                window.location.href = HostUrl + "product/details/" + value.url;
            }
        }
    };


    useEffect(()=>{
        updateSuggestionArr(productFound)
        setShowSuggestions(true)
        console.log(productFound);
    },[productFound])

    return(
        <div className="row text-center ">
                <div id="scrollable1" >
                    {
                    (showSuggestions && suggestionArr.length>0) ?
                    (suggestionArr.map((sug, index)=>{
                        let productStyle = sug.type === "P" ? {color: "blue", fontWeight: "500"} : {};
                        return(
                            <p className="scroll-element1 mb-1" key={index} style={productStyle} onClick={() => handleSuggestionClick(sug)}>{sug.name}</p>
                        )
                    }))
                    :
                    ""
                    }
                </div>
        </div>
    )
}
import { useState } from "react";
import Swal from "sweetalert2";
import { getBaseURL } from "./url";


const Login = () =>{
    const[userLogin, setUserLogin] = useState({ email : "", password : "" })
    const baseURL = getBaseURL();

    const  handleLogin = (e)=>{
        setUserLogin({...userLogin, [e.target.name] : e.target.value})
    }

    const checkLogin = async (e) =>{
        e.preventDefault();
        let url =  baseURL + "login/auth"
        let user = userLogin;
        console.log(user)
        let postdata = {
            headers:{'content-type':'application/json'},
            method:"post",
            body:JSON.stringify(user)
        }
        await fetch(url, postdata)
        .then(response=>response.json())
        .then(result=>{
            console.log(result)
            if(result.status === 'SUCCESS')
            {
                localStorage.setItem("username", result.name);
                localStorage.setItem("tokenno", result.tokenno);
                Swal.fire(result.message)
                setTimeout(()=>{ window.location.href = "/" },2000)
            }else{
                Swal.fire(result.message)
            }
        })
    }

    
    return(
        <div className="container">
            <div className="row">
                <div className="col-lg-12 pt-3">
                <form>
                    <div className="row mt-3">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4  shadow rounded-4">
                        <h3 className="text-center m-4">USER LOGIN</h3>
                        <div className="form-floating mb-4">
                        <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" onChange={obj=>setUserLogin({...userLogin,email : obj.target.value})}/>
                        <label htmlFor="floatingInput">Email address</label>
                        </div>
                        <div className="form-floating mb-4">
                        <input type="password" className="form-control" id="floatingPassword" placeholder="Password"  onChange={obj=>setUserLogin({...userLogin,password : obj.target.value})} />
                        <label htmlFor="floatingPassword">Password</label>
                        </div>
                        <div className="d-flex flex-column mb-4">
                        <button className="btn btn-primary m-1" onClick={(e) => checkLogin(e)}>Login</button>
                        <button className="btn btn-outline-primary m-1" type="reset">Reset</button>
                        </div>
                        <p className="text-center">Don't have an account? <span className="register-link" onClick={()=>{window.location.href = "/#/register" }}>Register</span></p>
                    </div>
                    <div className="col-lg-4"></div>
                    </div>
                </form>
                </div>
            </div>
        </div>
    )
}

export default Login;
import {  useContext, useEffect, useState } from "react";
import { ProductContext } from "../context/productContext";
import { getBaseURL } from "./url";


const CategorySearch = () =>{
    const[categorylist, updateCategoryList] = useState([]);
    const { selectedCategory, updateSelectedCategory} = useContext(ProductContext)
    const baseURL = getBaseURL();


    const getCategory = async() =>{
        let url =  baseURL + "category/getall";
        await fetch(url)
        .then(response=>response.json())
        .then(resultArr=>{
            let newData = resultArr.reverse();
            updateCategoryList(newData);
        })
    }

    useEffect(()=>{getCategory()}, [])
    return(
        <div className="scrollable-container" >
            {categorylist.map((category, index)=>{
                return(
                        <p className={`scroll-element ps-2 ${selectedCategory.catid === category.catid ? 'highlighted' : ''}`} 
                        key={index} onClick={updateSelectedCategory.bind(this, category)} >{category.categoryname}</p>            
                )
            })}
        </div>
    )
}


export default CategorySearch;
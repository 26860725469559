const Footer = () =>{
    return(
        <div className="wrapper">
            <footer className="footer">
                <div className="container">
                <h5 className="text-danger">This Website is for Testing Only</h5>
                </div>
            </footer>
        </div>
    )
}

export default Footer;
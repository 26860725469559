import { useEffect, useState } from "react";
import { Blocks } from "react-loader-spinner";
import { getBaseURL } from "./url";
import Swal from "sweetalert2";

const Orders = () =>{
    const[orderlist, setOrderList] = useState([]);
    const[loading, setLoading] = useState(false);
    const baseURL = getBaseURL();

    const getOrders = async() =>{
        setLoading(true)
        let url =  baseURL + "cart/myorder";
        let data = {
            userid : localStorage.getItem('tokenno')   
        }
        let postData = {
            headers : { "Content-Type" : "application/json"},
            method : 'POST',
            body : JSON.stringify(data) 
        }
        await fetch(url, postData)
        .then(response => response.json())
        .then(msg => {
            console.log(msg);
            setOrderList(msg.reverse())
            setLoading(false)
        }); 

    }

    useEffect(()=>{
        if(localStorage.getItem('tokenno'))
        {
            getOrders();
        }else{
            Swal.fire('Need to login')
            window.location.href = "/#/login";
        }
    }, [])
    return(
        <div className="container">
            <div className="row">
                <h2 className="text-center text-info">Order Page</h2>
            </div>
            {
            (loading) ? 
            (<div className="loader-holder-order">
                <Blocks wrapperClass="loader-order" height={100} width={100} />
            </div>) :
            (<div className="row">
                {/* <div className="col-lg-3">
                    <h4 className="text-center">Order IDs</h4>
                    <div id="list-example" class="list-group">
                    {orderlist.map((order, orderIndex) => (
                                order[0] && order[0].orderid !== 'null' ? (
                                <a class="list-group-item list-group-item-action" key={orderIndex}
                                 href={`#item-${orderIndex}`}>#{order[0].orderid}</a>
                                ) : null
                            ))}
                    </div>
                </div> */}
                
                <div className="col-lg-12">
                    <div>
                        {orderlist.map((order, orderIndex) => (
                            <div key={orderIndex} className="table-responsive mb-3">
                                {order[0] && order[0].orderid !== 'null' ? (
                                    <h5 id={`item-${orderIndex}`}>Order #{order[0].orderid}</h5>
                                ) : null}
                                <table className="table table-hover mb-5 mt-3 text-center border rounded-2">
                                    <thead>
                                        <tr>
                                            <th className="table-heading">ID</th>
                                            <th className="table-heading">Product Name</th>
                                            <th className="table-heading">Quantity</th>
                                            <th className="table-heading">Price Per Unit</th>
                                            <th className="table-heading">Amount</th>
                                            <th className="table-heading">Payment Mode</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {order.map((product, prodIndex) => (
                                            <tr key={prodIndex}>
                                                <td>{product.id}</td>
                                                <td>{product.productname}</td>
                                                <td>{product.quantity}</td>
                                                <td>Rs. {product.priceperunit}</td>
                                                <td>Rs. {product.quantity * product.priceperunit}</td>
                                                <td>{product.mode.toUpperCase()}</td>
                                            </tr>
                                        ))}
                                        <tr className="fw-bold table-light">
                                            <td colSpan={3}></td>
                                            <td  className="text-center "><strong>Total:</strong></td>
                                            <td>
                                                {order.reduce((total, product) => (
                                                    total + product.quantity * product.priceperunit
                                                ), 0)}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        ))}
                    </div>
                </div>
            </div>)
            }
        </div>
    )
}

export default Orders;
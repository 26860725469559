import React, { useState } from 'react';

function Register() {
    const[registerDetails, setRegisterDetails] = useState({name : "", email : "", password : ""})

  const handleRegister = (e) => {
    e.preventDefault()
    console.log(registerDetails)
    // Handle registration logic here
  };

  return (
    <div className="container">
      <div className="row ">
        <div className="col-lg-12 pt-3">
          <form>
            <div className="row mt-3">
              <div className="col-lg-4 "></div>
              <div className="col-lg-4 shadow rounded-4">
                <h4 className="text-center m-4">REGISTER USER</h4>
                <div className="form-floating mb-4">
                  <input type="text" className="form-control" id="floatingName" placeholder="Your Name" onChange={obj=>setRegisterDetails({...registerDetails, name : obj.target.value})} />
                  <label htmlFor="floatingName">Name</label>
                </div>
                <div className="form-floating mb-4">
                  <input type="email" className="form-control" id="floatingEmail" placeholder="name@example.com" onChange={obj=>setRegisterDetails({...registerDetails, email : obj.target.value})} />
                  <label htmlFor="floatingEmail">Email address</label>
                </div>
                <div className="form-floating mb-4">
                  <input type="password" className="form-control" id="floatingPassword" placeholder="Password" onChange={obj=>setRegisterDetails({...registerDetails, password : obj.target.value})} />
                  <label htmlFor="floatingPassword">Password</label>
                </div>
                <div className="d-flex flex-column mb-4">
                  <button className="btn btn-primary m-1 " onClick={(e) => handleRegister(e)}>Register</button>
                  <button className="btn btn-outline-primary m-1" type="reset">Reset</button>
                </div>
                <p className="text-center">Already have an account? <a href="/#/login">Login</a></p>
              </div>
              <div className="col-lg-4"></div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Register;

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { getBaseURL } from "./url";

const Payment = () =>{

    const amount = useParams()
    const[paymentMode, setPaymentMode] = useState("")
    const baseURL = getBaseURL();

    const paynow = async(e) =>{
        if(paymentMode === "CARD"){
            e.preventDefault();
        }
        let url =  baseURL + "cart/paynow";
        let orderdata = {mode:paymentMode, orderid: localStorage.getItem('orderid'), userid:localStorage.getItem('tokenno'), total:parseInt(amount.amount)+100};
        console.log(orderdata);
        let postdata = {
            headers:{'content-type':'application/json'},
            method:"post",
            body:JSON.stringify(orderdata)
        }
        await fetch(url, postdata)
        .then(response=>response.text())
        .then(msg=>{
            console.log(msg);
            Swal.fire(msg);
            // Cart reset is pending
            localStorage.removeItem("orderid");
            window.location.href = "/";
        })
    }


    useEffect(()=>{
        console.log(amount);
    }, [amount])
    return(
        <div className="container bg-light">
            <div className="row m-4">
                <h1 className="text-info text-center"> Payment Page </h1>
            </div>

            <div className="row">
                <div className="col-lg-6">
                   <div>
                        <h6>Order ID - #{localStorage.getItem('orderid')} </h6>
                        <h5>Amount to pay - <span className="text-success">Rs. {parseInt(amount.amount) + 100}.00</span></h5>
                   </div>
                </div>

                <div className="col-lg-6">
                    <div className="row">
                    <div className="form-floating">
                        <select className="form-select" id="floatingSelectGrid" onChange={obj=>setPaymentMode(obj.target.value)}>
                            <option selected>Open this select menu</option>
                            <option value="CASH">CASH</option>
                            <option value="CARD">CARD</option>
                            <option value="UPI">UPI</option>
                        </select>
                        <label htmlFor="floatingSelectGrid" className="fw-bold ms-2">Choose Payment method</label>
                    </div>
                    </div>
                </div>
            </div>

            <div className="row text-center bg-white mt-5">
                <div className="col-lg-2"></div>

                <div className="col-lg-8">
                    {paymentMode === "CASH" ? (
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-lg-9">
                        <   h3 className="m-3">Need to Pay Cash - Rs. {parseInt(amount.amount) + 100}.00</h3>
                        </div>
                        <div className="col-lg-3">
                            <button className="btn btn-warning" onClick={paynow}>Pay Now</button>
                        </div>
                    </div>
                    ) : paymentMode === "CARD" ? (
                    <form>
                        <div className="row">
                            <h3 className="mt-3 mb-3">Enter Card Details</h3>
                            <div className="col-lg-3"></div>
                            <div className="col-lg-6">
                                <div className="form-floating mb-3">
                                    <input type="number" className="form-control" id="cardNumber" placeholder="Card Number" />
                                    <label htmlFor="cardNumber">Card Number</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" id="cardHolderName" placeholder="Card Holder Name" />
                                    <label htmlFor="cardHolderName">Card Holder Name</label>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-floating mb-3">
                                        <input type="number" className="form-control" id="cvv" placeholder="CVV" />
                                        <label htmlFor="cvv" className="ms-3">CVV</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-floating mb-3">
                                            <input type="date" className="form-control" id="expiryDate" placeholder="Expiry Date" />
                                            <label htmlFor="expiryDate">Expiry Date</label>
                                        </div>
                                    </div>
                                </div>
                                <button className="btn btn-warning" onClick={(e)=>paynow(e)}>Pay Now</button>
                            </div>
                        </div>
                    </form>
                    ) : paymentMode === "UPI" ? (
                    <div className="row m-3 d-flex justify-content-center align-items-center">
                        <div className="form-floating mb-3 col-lg-9">
                            <input type="text" className="form-control" id="upiId" placeholder="UPI ID" />
                            <label htmlFor="upiId">UPI ID</label>
                        </div>
                        <div className="col-lg-3">
                            <button className="btn btn-warning" onClick={paynow}>Pay Now</button>
                        </div>
                    </div>
                    ) : (null)}
                </div>

                <div className="col-lg-2"></div>
            </div>
        </div>
    )
}

export default Payment;
import { useContext, useEffect, useState } from "react";
import { ProductContext } from "../context/productContext";
import { SearchSuggest } from "./searchSuggest";
import { useLocation, useParams } from "react-router-dom";
import Image from "../img/apple.png"
import Image1 from "../img/apple1.png"
import Image2 from "../img/apple2.png"
import Image3 from "../img/apple3.png"
import Image4 from "../img/apple4.png"
import { getBaseURL } from "./url";


const Details = () =>{
    const[productlist, updateProductList] = useState([]);
    const {purl} = useParams();
    const[displayProduct, updateDisplayProduct] = useState(
        {brandid : "", categoryid : "", productid : "", brandname: "",categoryname : "", details : "", price : "", productname : "", quantity : ""});
    const[search, setSearch] = useState("");
    const[productSuggested, updateProductSuggested] = useState([]);
    const {wishlist, setWishlist,  addItemToCart, loading, selectedBrand, selectedCategory, updateSelectedBrand, updateSelectedCategory} = useContext(ProductContext)
    const baseURL = getBaseURL();
    const[selectedImage, updateSelectedImage] = useState(baseURL + "assets/product/1.jpg")

    //  getting all listed products
        const getProduct = async() =>{
            let url =  baseURL + "product/searchproductbyurl";
            let searchData = {url : purl, type : "pdetails"};
            console.log(searchData);
            let postData = {
                headers : {'Content-type' : 'application/json'},
                method : "POST",
                body : JSON.stringify(searchData)
            }
           
            console.log(postData);
            await fetch(url, postData)
            .then(response=>response.json())
            .then(resultArr=>{
                console.log(resultArr);
                updateDisplayProduct(resultArr);
            })
            .catch(err=> console.log("Error in getting product"))
        }


    //  search & filter product 
    const findProductbySearch = () => {
        if (search === "") {
            updateDisplayProduct(productlist);
        } else {
            let filteredProducts = productlist.filter((product) =>
                product.productname.toLowerCase().includes(search.toLowerCase()) ||
                product.details.toLowerCase().includes(search.toLowerCase()) ||
                product.price.toLowerCase().includes(search.toLowerCase())
            );
            updateDisplayProduct(filteredProducts);
        }
    };


    // search suggestion
    const searchSuggestion = async(value) =>{
        console.log(value);
        if(value === ""){
            setSearch("")
            updateProductSuggested([])
        }else{
            setSearch(value)
            let url =  baseURL + "product/search";
        let searchData = {key: value};
        let postData = {
            headers : {'Content-type' : 'application/json'},
            method : "POST",
            body : JSON.stringify(searchData)
        }
       
        console.log(postData);
        await fetch(url, postData)
        .then(response=>response.json())
        .then(resultArr=>{
            console.log(resultArr);
            let productBasedOnSuggest = resultArr
            console.log(productBasedOnSuggest);
            updateProductSuggested(productBasedOnSuggest)
        })
        }
    }


    // Search By Category & Brand
    // const applyFilter = () =>{
    //     let url =  baseURL + "product/searchproduct";
    //     let filterData = {categoryid: selectedCategory.catid, brandid:selectedBrand.brandid};
    //     console.log(filterData); 
    //     let postdata = {
    //         headers:{'content-type':'application/json'},
    //         method:"post",
    //         body:JSON.stringify(filterData)
    //     }
    //     fetch(url, postdata)
    //     .then(response=>response.json())
    //     .then(resultArr=>{
    //         console.log(resultArr);
    //         updateDisplayProduct(resultArr);
    //     })
    // }
    


    // UseEffects
    useEffect(()=>{getProduct()}, [])
    // useEffect(()=>{applyFilter()}, [selectedBrand, selectedCategory])

    
        
    return( 
        <>
            <div className="container-fluid ">
                {/* Search Part */}
                <div className="row pt-3">
                    <div className="col-lg"></div>
                    <div className="col-lg-5">
                        <div className="input-group">
                            <input type="text" className="search-bar form-control formcontrol-lg"  placeholder={purl} 
                                onChange={obj=>searchSuggestion(obj.target.value)} aria-label="Input group example" aria-describedby="btnGroupAddon" value={search}/>
                            <button className="btn input-group-text border" id="btnGroupAddon" onClick={findProductbySearch}>
                                <i className="fa fa-magnifying-glass"></i>
                            </button>
                        </div>
                            <SearchSuggest productFound={productSuggested} setSearch={setSearch} />
                    </div>
                    <div className="col-lg"></div>
                </div>

                <div className="container">
                    <div className="row mt-4">
                        <div className="details-main-block">
                            <div className="detail-img-block">
                                <div className="detail-img">
                                    <img src={baseURL + "assets/product/1.jpg"} alt="" width={80} onClick={()=>updateSelectedImage(baseURL + "assets/product/1.jpg")} />
                                    <img src={baseURL + "assets/product/2.jpg"} alt="" width={80}  onClick={()=>updateSelectedImage(baseURL + "assets/product/2.jpg")} />
                                    <img src={baseURL + "assets/product/3.jpg"} alt="" width={80} onClick={()=>updateSelectedImage(baseURL + "assets/product/3.jpg")}  />
                                    <img src={baseURL + "assets/product/5.jpg"} alt="" width={80}  onClick={()=>updateSelectedImage(baseURL + "assets/product/5.jpg")} />
                                </div>
                                <div className="mb-3">
                                    <img src={selectedImage} alt="" className="detail-main-img" />
                                </div>
                            </div>
                            <div className="details-text">
                                <h1>{displayProduct.productname}</h1>
                                <small className="pt-0">in {displayProduct.categoryname}/{displayProduct.brandname} </small>
                                <h2 className="text-center ">Rs. {displayProduct.price}</h2>
                                <p><strong>Description - </strong>{displayProduct.details}</p>
                                <div className="row mt-3">
                                    <div className="product-detail-btn">
                                        <button className="btn btn-lg btn-success w-100" onClick={addItemToCart.bind(this, displayProduct)}>Add to  <i className="fa fa-cart-arrow-down fa-xl"></i></button>
                                        {
                                            (wishlist.find((wishId) => wishId === displayProduct.productid)) ?
                                            <button className="btn btn-lg btn-danger wishlist-btn w-100" onClick={(e) => {setWishlist(displayProduct.productid)}}> Remove from <i className="fa fa-heart fa-xl"></i></button> : 
                                            <button className="btn btn-lg btn-outline-danger wishlist-btn w-100" onClick={(e) => {setWishlist(displayProduct.productid)}}> Add to <i className="fa-regular fa-heart fa-xl"></i></button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </>
    )
}


export default Details;
import { useContext, useEffect, useState } from "react";
import CategorySearch from "./searchCategory";
import Image from "../img/apple.png"
import { ProductContext } from "../context/productContext";
import BrandSearch from "./searchBrand";
import ReactPaginate from "react-paginate";
import { Blocks } from "react-loader-spinner";
import { SearchSuggest } from "./searchSuggest";
import { useParams } from "react-router-dom";
import ProductElement from "./productelement";
import { getBaseURL } from "./url";


const Search = () =>{
    const[productlist, updateProductList] = useState([]);
    const {purl} = useParams();
    const[displayProduct, updateDisplayProduct] = useState([]);
    const[search, setSearch] = useState("");
    const[productSuggested, updateProductSuggested] = useState([]);
    const baseURL = getBaseURL();
    const {wishlist, setWishlist,  addItemToCart, loading, selectedBrand, selectedCategory, updateSelectedBrand, updateSelectedCategory} = useContext(ProductContext)

    //  search by brand
    const getProduct = async() =>{
        let url =  baseURL + "product/searchproductbyurl";
        let searchData = {url : purl, type : ""};
        let postData = {
            headers : {'Content-type' : 'application/json'},
            method : "POST",
            body : JSON.stringify(searchData)
        }
       
        console.log(postData);
        await fetch(url, postData)
        .then(response=>response.json())
        .then(resultArr=>{
            console.log(resultArr);
            updateDisplayProduct(resultArr);
        })
        .catch(err=> console.log("Error in getting product"))
    }


    //  search & filter product 
    const findProductbySearch = () => {
        if (search === "") {
            updateDisplayProduct(productlist);
        } else {
            let filteredProducts = productlist.filter((product) =>
                product.productname.toLowerCase().includes(search.toLowerCase()) ||
                product.details.toLowerCase().includes(search.toLowerCase()) ||
                product.price.toLowerCase().includes(search.toLowerCase())
            );
            updateDisplayProduct(filteredProducts);
        }
    };


    // search suggestion
    const searchSuggestion = async(value) =>{
        console.log(value);
        if(value === ""){
            setSearch("")
            updateProductSuggested([])
        }else{
            setSearch(value)
            // let productBasedOnSuggest = productlist.filter((product,index)=>product.productname.toLowerCase().includes(value))
            // console.log(productBasedOnSuggest);
            // updateProductSuggested(productBasedOnSuggest)
            let url =  baseURL + "product/search";
        let searchData = {key: value};
        let postData = {
            headers : {'Content-type' : 'application/json'},
            method : "POST",
            body : JSON.stringify(searchData)
        }
       
        console.log(postData);
        await fetch(url, postData)
        .then(response=>response.json())
        .then(resultArr=>{
            console.log(resultArr);
            let productBasedOnSuggest = resultArr
            console.log(productBasedOnSuggest);
            updateProductSuggested(productBasedOnSuggest)
        })
        }

    }

    // Search By Category & Brand
    const applyFilter = () =>{
        if(selectedCategory.catid !="" || selectedBrand.brandid !=""){
            let url = baseURL + "product/searchproduct";
            let filterData = {categoryid: selectedCategory.catid, brandid:selectedBrand.brandid};
            console.log(filterData); 
            let postdata = {
                headers:{'content-type':'application/json'},
                method:"post",
                body:JSON.stringify(filterData)
            }
            fetch(url, postdata)
            .then(response=>response.json())
            .then(resultArr=>{
                console.log(resultArr);
                updateDisplayProduct(resultArr);
            })
        }
    }
    
    
 
    // code for pagination config
    const PER_PAGE = 12;
    	const [currentPage, setCurrentPage] = useState(0);
    	function handlePageClick({ selected: selectedPage }) {
        	setCurrentPage(selectedPage)
    	}
    	const offset = currentPage * PER_PAGE;
    	const pageCount = Math.ceil(displayProduct.length / PER_PAGE);

    // UseEffects
    useEffect(()=>{applyFilter()}, [selectedBrand, selectedCategory])
    useEffect(()=>{getProduct()}, [purl])

    
        
    return( 
        <>
            <div className="container-fluid bg-light">
                {/* Search Part */}
                <div className="row pt-3">
                    <div className="col-lg"></div>
                    <div className="col-lg-5">
                        <div className="input-group">
                            <input type="text" className="search-bar form-control formcontrol-lg"  placeholder={purl} 
                                onChange={obj=>searchSuggestion(obj.target.value)} aria-label="Input group example" aria-describedby="btnGroupAddon" value={search}/>
                            <button className="btn input-group-text border" id="btnGroupAddon" onClick={findProductbySearch}>
                                <i className="fa fa-magnifying-glass"></i>
                            </button>
                        </div>
                        {/* <button onClick={abc}>press now</button> */}
                            <SearchSuggest productFound={productSuggested} setSearch={setSearch} />
                    </div>
                    <div className="col-lg"></div>
                </div>

                <div className="row mt-3 p-1">
                    {/* Category & Search Part */}
                    <div className="col-lg-3">
                        <div class="accordion mb-3" id="accordionPanelsStayOpenExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                                        Search By Category
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show">
                                <div class="accordion-body p-2">
                                    <CategorySearch />
                                </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                    Search By Brand
                                </button>
                                </h2>
                                <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse">
                                <div class="accordion-body p-2">
                                    <BrandSearch />
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Product Display Part */}
                    <div className="col-lg-9">
                    <div className="row">
                        {(selectedCategory.categoryname === "" && selectedBrand.brandname === "") ? null :
                            <div className="col-lg-12 ps-4 p-3">
                                {(selectedCategory.categoryname === "") ? "" :
                                    <div className="row  filter-icon" onClick={()=>updateSelectedCategory({categoryname : "", catid : ""})}>
                                        <span>{selectedCategory.categoryname}</span>
                                    </div>
                                }
                                {(selectedBrand.brandname === "") ? "" :
                                    <div className="row m-3 filter-icon" onClick={()=>updateSelectedBrand({brandname : "", brandid : ""})}>
                                        <span>{selectedBrand.brandname}</span>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    
                    <div className="row">
                            <div className="col-lg-12">
                                <div className=" shadow products-container">
                                    <div className="row">
                                        {loading ? 
                                        (<div className="loader-holder-product">
                                            <Blocks wrapperClass="loader-product" height={160} width={160} />
                                        </div>)
                                        :
                                        <div className="product-list">
                                            {
                                                displayProduct.slice(offset, offset + PER_PAGE).map((product,index)=>{
                                                    return(
                                                        <ProductElement key={index} product={product}/>
                                                    )
                                                })
                                            }
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
            {/* React Paginate Code */}
            <div className="mb-4 mt-4">
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
			                containerClassName={"pagination  justify-content-center"}
                            pageClassName={"page-item "}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active primary"}
                        />
            </div>
        </>
    )
}


export default Search;
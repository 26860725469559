import { useEffect, useState } from 'react';
import './App.css';
import NavBar from './components/navbar';
import ProductPage from './components/productpage';
import { ProductContext } from './context/productContext';
import Swal from "sweetalert2";
import Login from './components/login';
import {Routes, Route, HashRouter} from "react-router-dom";
import Payment from './components/payment';
import Orders from './components/orders';
import CartPage from './components/cartpage';
import Search from './components/search';
import Details from './components/details';
import { getBaseURL } from './components/url';
import Footer from './components/footer';
import Register from './components/register';



function App() {
  const[wishlist, updateWishlist] = useState([])
  const[localCartlist, updateLocalCartlist] = useState([])
  const[loading, setLoading] = useState(false)
  const [selectedBrand, setSelectedBrand] = useState({brandname : "", brandid : ""});
  const [selectedCategory, setSelectedCategory] = useState({categoryname : "", catid : ""});
  const baseURL = getBaseURL();

  let orderid = "";
  
  const setWishlist = (productid) =>{   
    if((wishlist.find((wishId)=>wishId === productid)) !== undefined)
    {
        updateWishlist(wishlist.filter((wishId)=>wishId!==productid))
        Swal.fire('Product Removed to Wishlist')
    }
    else{
        updateWishlist([...wishlist, productid])
        Swal.fire('Product Added to Wishlist')
    } 
  }

  // Filter By Category and Brand
  const updateSelectedBrand = (brand) => {
    // console.log(brand);
    if (selectedBrand.brandid === brand.brandid) {
      setSelectedBrand({brandname : "", brandid : ""}); // Remove selection if same category is clicked again
  } else {
    const {brandid, brandname} = brand;
    setSelectedBrand({brandname, brandid})
  }
  };

  const updateSelectedCategory = (category) => {
    // console.log(category);
    if (selectedCategory.catid === category.catid) {
      setSelectedCategory({categoryname : "", catid : ""}); // Remove selection if same category is clicked again
  } else {
    const{catid, categoryname} = category;
    setSelectedCategory({categoryname, catid})
  }

  };



  // this function will update localCartlist state
  const updateLocalCart = (cart) =>{
    let newCart = [...cart];
    updateLocalCartlist(newCart);
  }

  const updateServerCart = async(product, sign)=>{
    setLoading(true)
    console.log(product);
    let data = {}
    console.log("updateServer" +  localStorage.getItem('orderid'));

    let url = baseURL + "cart/addtocart";
    const {productid, priceperunit, quantity} = product;
    data = (sign === "+") ?{ productid,qty: "1", orderid :  localStorage.getItem('orderid') ,price : priceperunit }: { productid,qty: "-1", orderid :  localStorage.getItem('orderid') ,price : priceperunit };
    console.log(data);
    let postData = {
      headers : {'Content-Type' : "application/json"},
      method : 'POST',
      body : JSON.stringify(data)
    }
    await fetch(url, postData)
        .then(response=>response.text())
        .then(result=>{
          Swal.fire(result)
          getCart();
          setLoading(false)

        })
  }

  const addItemToCart = async(product) =>{
    setLoading(true)
    console.log(product);
    let url = baseURL + "cart/addtocart";
    const {productid, price} = product
    let data = { productid, qty : "1", orderid :  localStorage.getItem('orderid') ,price : price };
    console.log("addItemtocart" + localStorage.getItem('orderid'));

    let postData = {
      headers : {'Content-Type' : "application/json"},
      method : 'POST',
      body : JSON.stringify(data)
    }
    await fetch(url, postData)
        .then(response=>response.text())
        .then(result=>{
          Swal.fire(result)
          getCart();
          setLoading(false)

        })
  }

  // fetching cart details 1 times to check whether any item in cart there or not
  const getCart = async() =>{
    setLoading(true)
    let url =  baseURL + "cart/getcartitem"
    let orderEnquiry = {orderid : localStorage.getItem('orderid')}
    let postData = {
        headers : {'Content-Type' : "application/json"},
        method : 'POST',
        body : JSON.stringify(orderEnquiry)
    }
    await fetch(url, postData)
    .then(response=>response.json())
    .then(result=>{
        let newCart = result;
        console.log("Received Cart")
        console.log(newCart)
        updateLocalCartlist(newCart)
        setLoading(false)
    })
  }

  const data = {wishlist, setWishlist, localCartlist, addItemToCart, updateLocalCart , 
    getCart, updateServerCart, loading, selectedBrand, selectedCategory, updateSelectedBrand, updateSelectedCategory}
  
  useEffect(()=>getCart() , [])
  useEffect(()=>{
    if(localStorage.getItem('orderid') == null)
    {
    orderid = Math.floor(Math.random()*98765432100)
    localStorage.setItem('orderid', orderid)}
  } , [])

  return (
      <ProductContext.Provider value={data}>
        <HashRouter>
          <NavBar />
          <Routes>
            <Route path="/" element={<ProductPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/payment/:amount" element={<Payment />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/search/:purl" element={<Search />} />
            <Route path="/product/details/:purl" element={<Details />} />
          </Routes>
          <CartPage />
          <Footer />
        </HashRouter>
    </ProductContext.Provider>
  );
}

export default App;

import { useContext, useEffect, useState } from "react"
import Image from "../img/apple.png"
import { ProductContext } from "../context/productContext"
import Swal from "sweetalert2";
import { Blocks } from "react-loader-spinner";
import {getBaseURL } from "./url";


const CartPage = () =>{
    const {localCartlist, updateLocalCart, getCart, updateServerCart, loading} = useContext(ProductContext);
    const[amount, setAmount] = useState(0);
    const baseURL = getBaseURL();
    const[loginStatus, setLoginStatus] = useState(false);
    const[qty, setQty] = useState("");


    const qtyHandler = (operation, cart) =>{
        console.log(cart);
        let improvedCart = [...localCartlist];
        let index = improvedCart.findIndex((item) => item.productid === cart.productid);
        setQty(improvedCart[index].quantity)

        if (operation === "+") {
            improvedCart[index].quantity = parseInt(improvedCart[index].quantity ) + 1;
            updateServerCart(improvedCart[index], "+")

        } else {   
            improvedCart[index].quantity = parseInt(improvedCart[index].quantity ) - 1;
            updateServerCart(improvedCart[index], "-")
        }
        if(improvedCart[index].quantity === 0){
            delProduct(improvedCart[index])
            improvedCart.splice(index, 1);  
        }
       
            updateLocalCart(improvedCart);
    }

    const delProduct = async(product) =>{
        const {productid, id, quantity, orderid } = product;
        let url =  baseURL + "cart/removeCartItem"
        let delData = {
            productid,
            id,
            quantity
        }
        console.log(delData);
        let postData = {
            headers : { "Content-Type" : "application/json"},
            method : 'POST',
            body : JSON.stringify(delData) 
        }
        await fetch(url, postData)
        .then(response => response.text())
        .then(msg => {
            Swal.fire(msg)
            calAmount()
            getCart();
        })
        .catch(error => {
            console.error('Error:', error.message);
            Swal.fire(error)
        });
        
    }
    
    const checkout = () =>{
        if(loginStatus){
            // continue payment
            Swal.fire("Routing to Payment page")
            setTimeout(()=>{
                Swal.close()
                window.location.href = "/#/payment/" + amount}, 1000)
            
        }else{
            Swal.fire("Routing you to login page")
            window.location.href = "/#/login"
        }
        
    }

    const calAmount = () =>{
        let sum = 0;
        if (localCartlist.length > 0) {
            localCartlist.forEach(item => {
                sum += (item.quantity * item.priceperunit);
            });
        }
        setAmount(sum);
    }

    useEffect(()=>{
        let sum = 0;
        if (localCartlist.length > 0) {
            localCartlist.forEach(item => {
                sum += (item.quantity * item.priceperunit);
            });
        }
        setAmount(sum);
    },[localCartlist])
    
    // check whether user login or not
    useEffect(()=>{
        let username = localStorage.getItem('username')
        if(username ===null || username === ""){
            setLoginStatus(false)
        }else{
            setLoginStatus(true)
        }
    }, [])

    return(
            <div className="offcanvas offcanvas-end p-3" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header p-0">
                        {/* <div class> */}
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            <h5 className="offcanvas-title text-center " id="offcanvasRightLabel">Cart Summary</h5>
                        {/* </div> */}
                        <div>
                        </div>
                </div>
                <div className="offcanvas-body">
                    <div className="row cart-header">
                        <small className="fw-bold">{localCartlist.length} items in your Bag</small>
                        <small className="fw-bold">Orderid - #<span className="text-primary">{localStorage.getItem('orderid')}</span></small>
                        <hr />  
                    </div>
                    <div className="row">
                        {
                            (localCartlist.length > 0) ? 
                            (
                            <div className="row bg-white pe-3">
                                {
                                    localCartlist.map((cart,index)=>{
                                        return(
                                            <div className="row bg-light cart-item rounded " key={index}>
                                                <div className="cart-img">
                                                    <img src={Image} alt="NA" width={40}  />
                                                </div>
                                                <div className="cart-block2">
                                                    <div className=" cart-name mt-1">
                                                        <h6>{cart.productname}</h6>
                                                    </div>
                                                    <div className="cart-block3">
                                                        <div className=" pt-2 text-center cart-price">
                                                            <h6 className="text-danger">Rs. {cart.priceperunit}</h6>
                                                        </div>
                                                        <div className=" pt-2 cart-qty">
                                                        {
                                                            loading ? 
                                                            (<div className="loader-holder-cart">
                                                                <Blocks wrapperClass="loader-cart" height={50} width={50} />
                                                            </div>)
                                                            :
                                                            (<div className="btn-group pb-1" role="group" aria-label="Second group">
                                                                <button type="button" className="btn btn-outline-success btn-sm" onClick={qtyHandler.bind(this, "+", cart)}>
                                                                    <i className="fa fa-plus"></i>
                                                                </button>
                                                                <button type="button" className="btn btn-dark btn-sm" disabled>{cart.quantity}</button>
                                                                <button type="button" className="btn btn-outline-danger btn-sm" onClick={qtyHandler.bind(this, "-" , cart)}>
                                                                    <i className="fa fa-minus"></i>
                                                                </button>
                                                            </div>)
                                                        }
                                                        </div>
                                                        <div className=" pt-2 text-end ps-0 pe-0 cart-total">
                                                            <h6 className="text-danger">Rs. {cart.priceperunit * cart.quantity}</h6>
                                                        </div>
                                                    </div>
                                                        <div className=" text-center cart-del">
                                                            <i className="fa fa-trash text-danger fa-lg" onClick={delProduct.bind(this,cart)}></i>
                                                        </div>
                                                </div>
                                                
                                            </div>
                                        )
                                    })  
                                }
                            </div>
                            )
                            : 
                            ( <p className="text-center">Your cart is empty</p>)
                        }
                    </div>
                    {/* } */}
                    <hr />
                    <div className="row rounded p-1 m-0">
                        <div className="col">
                            <p className="p-0 m-0">Sub Total : </p>
                            <small>Delivery Charge : </small>
                            <h6>Total : </h6>
                        </div>
                        <div className="col text-end">
                            <p className="p-0 m-0">Rs. {amount}</p>
                            <small>Rs. {amount ? 100 : 0 }</small>
                            <h6 className="m-0">Rs. {amount + (amount ? 100 : 0) }</h6>
                        </div>    
                    </div>
                    <hr />
                    <div className="row">
                        <h6 className="">Address</h6>
                        <small className="text-danger fst-italic">Not Found</small>
                    </div>
                    <div className="row w-50 m-auto">
                            <button className="btn btn-primary rounded-3 m-1" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><i className="fa fa-arrow-left"></i> Return</button>
                            <button className="btn btn-outline-success rounded-3 m-1" onClick={checkout} disabled={localCartlist.length === 0} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"> Checkout <i className="fa fa-arrow-right"></i></button>
                    </div>
                </div>
            </div>
    )

}

export default CartPage;